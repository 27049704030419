<template>
  <section>
    <vue-headful :title="title" />
     <template v-if="DiferencialCLiente === 1">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <h1 class="fontD mt-5 pa-3 text-left">{{ title }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12">
            <v-card elevation="2" outlined>
              <v-card-title>Datos personales</v-card-title>
              <v-divider></v-divider>
              <v-form class="mt-3">
                <v-row>
                  <v-col cols="12" lg="6" md="12" sm="12">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Primer Nombre"
                      label="Primer Nombre"
                      v-model="infoResponse.persona.primerNombre"
                      :rules="nombreRules"
                      :disabled="!isEditMode"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" md="12">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Segundo Nombre"
                      label="Segundo Nombre"
                      v-model="infoResponse.persona.segundoNombre"
                      :rules="segundonombreRules"
                      :disabled="!isEditMode"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" md="12">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Primer Apellido"
                      label="Primer Apellido"
                      v-model="infoResponse.persona.primerApellido"
                      :rules="apellidoRules"
                      :disabled="!isEditMode"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" md="12">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Segundo Apellido"
                      label="Segundo Apellido"
                      v-model="infoResponse.persona.segundoApellido"
                      :rules="segundoapellidoRules"
                      required
                      :disabled="!isEditMode"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" md="12">
                    <v-select
                      v-model="
                        infoResponse.persona.personaIdentificacion[0]
                          .idIdentificacionTipo
                      "
                      :options="identificacion"
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Identificación Ciudadana"
                      label="Tipo de Identificacion"
                      item-value="idIdentificacionTipo"
                      :items="identificacion"
                      item-text="nombre"
                      required
                      :disabled="!isEditMode"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" lg="6" md="12">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Identificación Ciudadana"
                      label="Identificación Ciudadana"
                      v-model="
                        infoResponse.persona.personaIdentificacion[0]
                          .identificacion
                      "
                      required
                      :disabled="!isEditMode"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" md="12" sm="12">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Correo"
                      label="Correo"
                      v-model="infoResponse.persona.personacorreo[0].correo"
                      :rules="[rules.required, rules.email]"
                      required
                      :disabled="!isEditMode"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Teléfono"
                      label="Teléfono"
                      v-model="infoResponse.persona.personatelefono[0].numero"
                      :disabled="!isEditMode"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex align-end flex-column">
                    <v-btn
                      outlined
                      class="white--text mr-5"
                      v-bind:color="editBtnColor"
                      v-on:click="enableEditMode"
                      >{{ editBtnLabel }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="2" outlined>
              <v-card-title>Pais y dirección de facturación</v-card-title>
              <v-divider></v-divider>
              <v-form class="mt-3">
                <v-row>
                  <v-col cols="12" lg="6" md="12" sm="12">
                    <v-select
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Pais"
                      label="Pais"
                      :items="paisList"
                      item-text="nombrePais"
                      item-value="idPais"
                      v-model="infoResponse.idPais"
                      required
                      :disabled="!isEditMode"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Estado"
                      label="Estado"
                      v-model="infoResponse.ciudad"
                      :disabled="!isEditMode"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Provincia"
                      label="Provincia"
                      v-model="infoResponse.provincia"
                      :disabled="!isEditMode"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Codigo Postal"
                      label="Codigo Postal"
                      v-model="infoResponse.codigoPostal"
                      :disabled="!isEditMode"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Dirección"
                      label="Dirección"
                      v-model="infoResponse.persona.direccion"
                      :disabled="!isEditMode"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex align-end flex-column">
                    <v-btn
                      outlined
                      class="white--text mr-5"
                      v-bind:color="editBtnColor"
                      v-on:click="enableEditMode"
                      >{{ editBtnLabel }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="2" outlined>
              <v-card-title>Cambio de Contraseña</v-card-title>
              <v-divider></v-divider>
              <v-form class="mt-3">
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Contraseña Actual"
                      label="Contraseña Actual"
                      v-model="currentPassword"
                      :disabled="!isPasswordEditMode"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Nueva Contraseña"
                      label="Nueva Contraseña"
                      v-model="password"
                      :disabled="!isPasswordEditMode"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Confirmar Contraseña"
                      label="Confirmar Contraseña"
                      v-model="confirmPassword"
                      :disabled="!isPasswordEditMode"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex align-end flex-column">
                    <v-btn
                      outlined
                      class="white--text mr-5"
                      v-bind:color="editPasswordBtnColor"
                      v-on:click="enablePasswordEditMode"
                      >{{ editPasswordBtnLabel }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <h1 class="fontD mt-5 pa-3 text-left">Tarjetas Registradas</h1>
          </v-col>
          <v-col v-for="(item, i) in desserts" :key="i" cols="12" lg="4" md="4">
            <template v-if="item.numeration.startsWith('4')">
              <v-col cols="12" md="12" lg="4">
                <v-hover v-slot="{ hover }">
                  <v-card
                    id="card-visa"
                    :elevation="hover ? 12 : 2"
                    :class="{ 'on-hover': hover }"
                    max-width="300"
                    class="mx-auto img_fondo_visa justify-start mb-6"
                  >
                    <v-row>
                      <v-col class="justify-start mt-n4">
                        <v-img
                          src="../../../assets/img/VISA-logo 1.png"
                          width="54"
                          height="33"
                          class="ml-1"
                        >
                        </v-img>
                      </v-col>
                    <v-dialog
                        v-model="modal"
                        persistent
                        max-width="290"
                      >
                    <template v-slot:activator="{ on, attrs }">
                       <v-hover v-slot:default="{ hover }">
                      <v-btn
                       class="mr-5"
                       v-bind="attrs"
                      v-on="on"
                      icon
                      text
                    >
                      <v-icon v-if="hover" class="appendRight"  color="red darken-4">
                       mdi-delete
                      </v-icon>
                    </v-btn>
                       </v-hover>
                    </template>
                    <v-card>
                      <v-card-title class="headline">
                       ¿Esta seguro que desea eliminar la terjeta?
                      </v-card-title>
                     
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="indigo lighten-5"
                      
                          @click="modal = false"
                          left
                        >
                        Cancelar
                        </v-btn>
                        <v-btn
                          color="red darken-4"
                          @click="modal = false"
                          class="white--text"
                        >
                          Eliminar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                    </v-dialog> 
                    </v-row>
                    <v-card-text class="my-n5">
                      <p class="N-Tarjeta text-center white--text">
                        {{ item.numeration }}
                      </p>
                      <p class="N-Tarjeta text-center white--text">
                        {{ item.expiration }}
                      </p>
                      <p class="N-Tarjeta text-center white--text">
                        {{ item.titular }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </template>
            <template v-if="item.numeration.startsWith('5')">
              <v-col cols="12" md="12" lg="4">
                <v-hover v-slot="{ hover }">
                  <v-card
                    id="card-master"
                    :elevation="hover ? 12 : 2"
                    :class="{ 'on-hover': hover }"
                    flat
                    max-width="300"
                    class="mx-auto img_fondo-Mastercard justify-end mb-6"
                  >
                    <v-row>
                      <v-col class="justify-start mt-n3">
                        <v-img
                          src="../../../assets/img/MasterCard.png"
                          width="60"
                          height="36"
                          class="ml-1"
                        >
                        </v-img>
                      </v-col>
                   <v-dialog
                        v-model="modal"
                        persistent
                        max-width="290"
                      >
                    <template v-slot:activator="{ on, attrs }">
                       <v-hover v-slot:default="{ hover }">
                      <v-btn
                       class="mr-5"
                       v-bind="attrs"
                      v-on="on"
                      icon
                      text
                    >
                      <v-icon v-if="hover" class="appendRight"  color="red darken-4">
                       mdi-delete
                      </v-icon>
                    </v-btn>
                       </v-hover>
                    </template>
                    <v-card>
                      <v-card-title class="headline">
                       ¿Esta seguro que desea eliminar la terjeta?
                      </v-card-title>
                     
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="indigo lighten-5"
                      
                          @click="modal = false"
                          left
                        >
                        Cancelar
                        </v-btn>
                        <v-btn
                          color="red darken-4"
                          @click="modal = false"
                          class="white--text"
                        >
                          Eliminar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                    </v-dialog>   
                    </v-row>
                    <v-card-text class="my-n5">
                      <p class="N-Tarjeta text-center white--text">
                        {{ item.numeration }}
                      </p>
                      <p class="N-Tarjeta text-center white--text">
                        {{ item.expiration }}
                      </p>
                      <p class="N-Tarjeta text-center white--text">
                        {{ item.titular }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </template>
          </v-col>
        </v-row>
        <template>
          <div class="text-center justify-center" id="tarjetas-paginator">
            <v-pagination
              v-model="paginaTarjeta"
              :length="paginaTarjetaTotales"
              :total-visible="10"
            ></v-pagination>
          </div>
        </template>
        <v-overlay :value="overlay">
          <v-progress-circular
            indeterminate
            size="64"
            color="#007BFF"
          ></v-progress-circular>
        </v-overlay>
      </v-container>
    </template>
  
    <template v-if="DiferencialCLiente === 2">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <h1 class="fontD mt-5 pa-3 text-left">{{ title }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12">
            <v-card elevation="2" outlined>
              <v-card-title>Datos personales</v-card-title>
              <v-divider></v-divider>
              <v-form class="mt-3">
                <v-row>
                  <v-col cols="12" lg="6" md="12" sm="12">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      label="Razón Social"
                      prepend-inner-icon="mdi-domain"
                      v-model="infoJuridico.empresa.razonSocial"
                      :rules="[(v) => !!v || 'Campo es obligatorio']"
                      required
                      :disabled="!isEditMode"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" md="12">
                    <v-select
                    dense
                     class="px-8"
                      color="blue darken-2"
                      id="juridico-cliente-clasificacion"
                      v-model="clientejuridico.clientejuridico.idClasificacionJuridico"
                      :items="clienteJuridicoClasificacion"
                      item-text="nombre"
                      item-value="idClasificacionJuridico"
                      label="Clasificación"
                      prepend-inner-icon="mdi-font-awesome"
                      :rules="[(v) => !!v || 'Campo es obligatorio']"
                      required
                      :disabled="!isEditMode"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" md="12">
                    <v-select
                      dense
                      class="px-8"
                      color="blue darken-2"
                      label="Actividad"
                      prepend-inner-icon="mdi-briefcase"
                      v-model="clientejuridico.clientejuridico.idClienteActividad"
                      item-text="nombre"
                      item-value="idClienteActividad"
                      :items="clienteActivadadJuridico"
                      :rules="[(v) => !!v || 'Campo es obligatorio']"
                      required
                      :disabled="!isEditMode"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" lg="6" md="12">
                    <v-text-field
                     dense
                      class="px-8"
                      color="blue darken-2"
                      id="correoJuridico"
                      label="Correo"
                      prepend-inner-icon="mdi-mail"
                      v-model="infoJuridico.empresa.empresacorreo[0].correo"
                      :rules="[rules.required, rules.email]"
                      required
                      :disabled="!isEditMode"
                      
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" md="12">
                    <v-select
                     dense
                      class="px-8"
                      color="blue darken-2"
                      id="juridico-cliente-pais"
                      v-model="clientejuridico.clientejuridico.idPais"
                      item-value="idPais"
                      :options="paisList"
                      label="Pais"
                      :items="paisList"
                      item-text="nombrePais"
                      :rules="[(v) => !!v || 'Campo Obligatorio']"
                      prepend-inner-icon="mdi-font-awesome"
                      required
                      :disabled="!isEditMode"
                      
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" lg="6" md="12">
                    <v-text-field
                     dense
                      class="px-8"
                      color="blue darken-2"
                      v-model="infoJuridico.provincia"
                      label="Provincia"
                      prepend-inner-icon="mdi-city"
                      :rules="provinciaRules"
                      :disabled="!isEditMode"
                      required
                      
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex align-end flex-column">
                    <v-btn
                      outlined
                      class="white--text mr-5"
                      v-bind:color="editBtnColor"
                      v-on:click="enableEditMode"
                      >{{ editBtnLabel }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="2" outlined>
              <v-card-title>Pais y dirección de facturación</v-card-title>
              <v-divider></v-divider>
              <v-form class="mt-3">
                <v-row>
                  <v-col cols="12" lg="6" md="12" sm="12">
                    <v-text-field
                     dense
                      class="px-8"
                      color="blue darken-2"
                      v-model="infoJuridico.empresa.direccion"
                      label="Dirección"
                      prepend-inner-icon="mdi-map-marker"
                      :rules="direccionRules"
                      required
                      :disabled="!isEditMode"
                      
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                     dense
                      class="px-8"
                      color="blue darken-2"
                      v-model="infoJuridico.empresa.direccion"
                      label="Dirección"
                      prepend-inner-icon="mdi-map-marker"
                      :rules="direccionRules"
                      required
                      :disabled="!isEditMode"
                      
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-text-field
                     dense
                      class="px-8"
                      color="blue darken-2"
                      v-model="infoJuridico.empresa.nruc"
                      label="RUC"
                      prepend-inner-icon="mdi-counter"
                      :rules="[(v) => !!v || 'Campo es obligatorio']"
                      required
                      :disabled="!isEditMode"
                      
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                     dense
                      class="px-8"
                      color="blue darken-2"
                      v-model="infoJuridico.codigoPostal"
                      type="number"
                      prepend-inner-icon="mdi-mailbox"
                      label="Código Postal"
                      :disabled="!isEditMode"
                      :rules="[rules.required]"
                      required
                      
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                     dense
                      class="px-8"
                      color="blue darken-2"
                      id="juridico-telefono"
                      v-model="infoJuridico.empresa.empresatelefono[0].numero"
                      type="number"
                      prepend-inner-icon="mdi-phone"
                      label="Teléfono"
                      :rules="phoneRules"
                      :disabled="!isEditMode"
                      required
                      
                    ></v-text-field>
                  </v-col>
                  <v-text-field
                   dense
                      class="px-8"
                      color="blue darken-2"
                    v-model="infoJuridico.ciudad"
                    label="Ciudad"
                    prepend-inner-icon="mdi-city"
                    :rules="ciudadRules"
                    :disabled="!isEditMode"
                    required
                    
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-col class="d-flex align-end flex-column">
                    <v-btn
                      outlined
                      class="white--text mr-5"
                      v-bind:color="editBtnColor"
                      v-on:click="enableEditMode"
                      >{{ editBtnLabel }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="2" outlined>
              <v-card-title>Cambio de Contraseña</v-card-title>
              <v-divider></v-divider>
              <v-form class="mt-3">
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Contraseña Actual"
                      label="Contraseña Actual"
                      v-model="currentPassword"
                      :disabled="!isPasswordEditMode"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Nueva Contraseña"
                      label="Nueva Contraseña"
                      v-model="password"
                      :disabled="!isPasswordEditMode"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      dense
                      class="px-8"
                      color="blue darken-2"
                      placeholder="Confirmar Contraseña"
                      label="Confirmar Contraseña"
                      v-model="confirmPassword"
                      :disabled="!isPasswordEditMode"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex align-end flex-column">
                    <v-btn
                      outlined
                      class="white--text mr-5"
                      v-bind:color="editPasswordBtnColor"
                      v-on:click="enablePasswordEditMode"
                      >{{ editPasswordBtnLabel }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <h1 class="fontD mt-5 pa-3 text-left">Tarjetas Registradas</h1>
          </v-col>
          <v-col v-for="(item, i) in desserts" :key="i" cols="12" lg="4" md="4">
            <template v-if="item.numeration.startsWith('4')">
              <v-col cols="12" md="12" lg="4">
                <v-hover v-slot="{ hover }">
                  <v-card
                    id="card-visa"
                    :elevation="hover ? 12 : 2"
                    :class="{ 'on-hover': hover }"
                    max-width="300"
                    class="mx-auto img_fondo_visa justify-start mb-6"
                  >
                    <v-row>
                      <v-col class="justify-start mt-n4">
                        <v-img
                          src="../../../assets/img/VISA-logo 1.png"
                          width="54"
                          height="33"
                          class="ml-1"
                        >
                        </v-img>
                      </v-col>
                      <v-dialog v-model="modal" persistent max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                          <v-hover v-slot:default="{ hover }">
                            <v-btn
                              class="mr-5"
                              v-bind="attrs"
                              v-on="on"
                              icon
                              text
                            >
                              <v-icon
                                v-if="hover"
                                class="appendRight"
                                color="red darken-4"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </v-hover>
                        </template>
                        <v-card>
                          <v-card-title class="headline">
                            ¿Esta seguro que desea eliminar la terjeta?
                          </v-card-title>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="indigo lighten-5"
                              @click="modal = false"
                              left
                            >
                              Cancelar
                            </v-btn>
                            <v-btn
                              color="red darken-4"
                              @click="modal = false"
                              class="white--text"
                            >
                              Eliminar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      
                    </v-row>
                    <v-card-text class="my-n5">
                      <p class="N-Tarjeta text-center white--text">
                        {{ item.numeration }}
                      </p>
                      <p class="N-Tarjeta text-center white--text">
                        {{ item.expiration }}
                      </p>
                      <p class="N-Tarjeta text-center white--text">
                        {{ item.titular }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </template>
            <template v-if="item.numeration.startsWith('5')">
              <v-col cols="12" md="12" lg="4">
                <v-hover v-slot="{ hover }">
                  <v-card
                    id="card-master"
                    :elevation="hover ? 12 : 2"
                    :class="{ 'on-hover': hover }"
                    flat
                    max-width="300"
                    class="mx-auto img_fondo-Mastercard justify-end mb-6"
                  >
                    <v-row>
                      <v-col class="justify-start mt-n3">
                        <v-img
                          src="../../../assets/img/MasterCard.png"
                          width="60"
                          height="36"
                          class="ml-1"
                        >
                        </v-img>
                      </v-col>
                      <v-dialog v-model="modal" persistent max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                          <v-hover v-slot:default="{ hover }">
                            <v-btn
                              class="mr-5"
                              v-bind="attrs"
                              v-on="on"
                              icon
                              text
                            >
                              <v-icon
                                v-if="hover"
                                class="appendRight"
                                color="red darken-4"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </v-hover>
                        </template>
                        <v-card>
                          <v-card-title class="headline">
                            ¿Esta seguro que desea eliminar la terjeta?
                          </v-card-title>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="indigo lighten-5"
                              @click="modal = false"
                              left
                            >
                              Cancelar
                            </v-btn>
                            <v-btn
                              color="red darken-4"
                              @click="modal = false"
                              class="white--text"
                            >
                              Eliminar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                    <v-card-text class="my-n5">
                      <p class="N-Tarjeta text-center white--text">
                        {{ item.numeration }}
                      </p>
                      <p class="N-Tarjeta text-center white--text">
                        {{ item.expiration }}
                      </p>
                      <p class="N-Tarjeta text-center white--text">
                        {{ item.titular }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </template>
          </v-col>
        </v-row>
        <template>
          <div class="text-center justify-center" id="tarjetas-paginator">
            <v-pagination
              v-model="paginaTarjeta"
              :length="paginaTarjetaTotales"
              :total-visible="10"
            ></v-pagination>
          </div>
        </template>
        <v-overlay :value="overlay">
          <v-progress-circular
            indeterminate
            size="64"
            color="#007BFF"
          ></v-progress-circular>
        </v-overlay>
      </v-container>
    </template>
  </section>
</template>
<script>
import vueHeadful from "vue-headful";
import Vue from "vue";
import "vue-toast-notification/dist/theme-sugar.css";
export default {
  components: {
    vueHeadful,
  },
  mounted: async function() {
    await this.getAccountInfo();
    await this.getTipoIdentificacion();
    await this.getClasificacionJ();
    await this.getClienteActividadJuridico();
    await this.getPais();
    await this.getTarjetas();
  },
  watch: {
    paginaTarjeta: function() {
      this.getTarjetas();
    },
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 1500);
    },
  },
  data: () => ({
    overlay: false,
    modal: false,
    title: "Información de Cuenta",
    infoResponse: {},
    infoJuridico: {},
    isEditMode: false,
    editBtnLabel: "Editar",
    editBtnColor: "green",

    isPasswordEditMode: false,
    editPasswordBtnLabel: "Editar",
    editPasswordBtnColor: "green",

    identificacion: [],
    tarjetas: [],
    tarjetasPagination: [],
    paisList: [],
    paginaTarjeta: 1,
    paginaTarjetaTotales: 1,
    registrosPorPaginasTarjetas: 6,
    clienteActividadList: [],
    headers: [
      { text: "Numeracion", value: "numeration" },
      { text: "Expiracion", value: "expiration" },
      { text: "Titular", value: "titular" },
    ],
    desserts: [],
    clienteNaturalPut: {
      idClienteActividad: 0,
      esRegistradorAcreditado: true,
      idPais: 0,
      provincia: "",
      ciudad: "",
      codigoPostal: 0,
      persona: {
        primerNombre: "",
        segundoNombre: "",
        primerApellido: "",
        segundoApellido: "",
        direccion: "",
        personacorreo: [
          {
            correo: "",
          },
        ],
        personaIdentificacion: [
          {
            idIdentificacionTipo: 0,
            identificacion: "",
          },
        ],
        personatelefono: [
          {
            numero: "",
            esPrincipal: true,
            observacion: "",
          },
        ],
      },
    },
    DiferencialCLiente: 0,
    clienteJuridicoPut: {
      idClienteActividad: 0,
      esRegistradorAcreditado: true,
      idPais: 0,
      provincia: "",
      ciudad: "",
      codigoPostal: 0,
      idClasificacionJuridico: 0,
      siglas: "",
      empresa: {
        nruc: "",
        razonSocial: "",
        direccion: "",
        empresacorreo: [
          {
            correo: "",
          },
        ],
        empresatelefono: [
          {
            numero: "",
            esprincipal: true,
            observacion: "",
          },
        ],
      },
    },
    clienteActivadadJuridico: [],
   clientejuridico: {
      usuario: {
        password: "",
        confirmarPassword: "",
        usuario: "",
        correo: "",
      },
      clientejuridico: {
        idClienteActividad: 0,
        esRegistradorAcreditado: true,
        idPais: 0,
        provincia: "",
        ciudad: "",
        codigoPostal: 0,
        idClasificacionJuridico: 0,
        siglas: "",
        empresa: {
          nruc: "",
          razonSocial: "",
          direccion: "",
          empresacorreo: [
            {
              correo: "",
            },
          ],
          empresatelefono: [
            {
              numero: "",
              esprincipal: true,
              observacion: "",
            },
          ],
        },
      },
    },
    clienteJuridicoClasificacion: [],
    currentPassword: "**********************",
    password: "**********************",
    confirmPassword: "**********************",
    idClienteTipo: 0,
    phoneRules: [(v) => !!v || "Campo es obligatorio"],
    direccionRules: [(v) => !!v || "Campo es obligatorio"],
    provinciaRules: [(v) => !!v || "Campo es obligatorio"],
    ciudadRules: [(v) => !!v || "Campo es obligatorio"],
    identifiacionRules: [(v) => !!v || "Identificación es obligatorio"],
    segundoapellidoRules: [(v) => !!v || "Apellido es obligatorio"],
    apellidoRules: [(v) => !!v || "Apellido es obligatorio"],
    nombreRules: [(v) => !!v || "Nombre es obligatorio"],
    segundonombreRules: [(v) => !!v || "Nombre es obligatorio"],
    rules: {
      required: (value) => !!value || "Campo obligatorio.",
      counter: (value) => value.length <= 3 || "Maximo 3 caracteres",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Correo Invalido";
      },
    },
    passwordRules: [(v) => !!v || "Contraseña obligatoria"],
    confirmPasswordRules: [(v) => !!v || "Contraseña obligatoria"],
  }),
  methods: {
    toasterMessageResponse: function(msg, type) {
      Vue.$toast.open({
        message: msg,
        type: type,
      });
    },
    putClienteNatura: async function() {
      this.overlay = true;
      this.clienteNaturalPut = {
        idClienteActividad: this.infoResponse.idClienteActividad,
        esRegistradorAcreditado: true,
        idPais: this.infoResponse.idPais,
        provincia: this.infoResponse.provincia,
        ciudad: this.infoResponse.ciudad,
        codigoPostal: this.infoResponse.codigoPostal,
        persona: {
          primerNombre: this.infoResponse.persona.primerNombre,
          segundoNombre: this.infoResponse.persona.segundoNombre,
          primerApellido: this.infoResponse.persona.primerApellido,
          segundoApellido: this.infoResponse.persona.segundoApellido,
          direccion: this.infoResponse.persona.direccion,
          personacorreo: [
            {
              correo: this.infoResponse.persona.personacorreo[0].correo,
            },
          ],
          personaIdentificacion: [
            {
              idIdentificacionTipo: this.infoResponse.persona
                .personaIdentificacion[0].idIdentificacionTipo,
              identificacion: this.infoResponse.persona.personaIdentificacion[0]
                .identificacion,
            },
          ],
          personatelefono: [
            {
              numero: this.infoResponse.persona.personatelefono[0].numero,
              esPrincipal: true,
              observacion: "",
            },
          ],
          
        },
        
      };
      var token = localStorage.getItem(atob("usr"));

      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .put(this.$keys("PUT_CLIENTES_NATURAL"), this.clienteNaturalPut)
        .then((response) => {
          if (response.status === 200) {
            this.toasterMessageResponse(response.data.mensaje, "success");
            this.overlay = true;
            this.isEditMode = false;
            this.editBtnLabel = "Editar";
          }
        })
        .catch((error) => {
          if (error)
            this.toasterMessageResponse("A ocurrido un error", "error");
        });
      this.overlay = true;
    },
    putClienteJuridico: async function() {
      this.overlay = true;
      this.clienteJuridicoPut = {
        idClienteActividad: this.infoResponse.idClienteActividad,
        esRegistradorAcreditado: true,
        idPais: this.infoResponse.idPais,
        provincia: this.infoResponse.provincia,
        ciudad: this.infoResponse.ciudad,
        codigoPostal: this.infoResponse.codigoPostal,
        idClasificacionJuridico: this.infoResponse.idClasificacionJuridico,
        siglas: this.infoResponse.siglas,
        empresa: {
          nruc: this.infoResponse.empresa.nruc,
          razonSocial: this.infoResponse.empresa.razonSocial,
          direccion: this.infoResponse.empresa.direccion,
          empresacorreo: [
            {
              correo: this.infoResponse.empresa.empresacorreo[0].correo,
            },
          ],
          empresatelefono: [
            {
              numero: this.infoResponse.empresa.empresatelefono[0].numero,
              esprincipal: true,
              observacion: "",
            },
          ],
        },
      };
      var token = localStorage.getItem(atob("usr"));

      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .put(this.$keys("PUT_CLIENTES_JURIDICO"), this.clienteJuridicoPut)
        .then((response) => {
          if (response.status === 200) {
            this.toasterMessageResponse(response.data.mensaje, "success");
            this.overlay = true;
            this.isEditMode = false;
            this.editBtnLabel = "Editar";
          }
        })
        .catch((error) => {
          if (error)
            this.toasterMessageResponse("A ocurrido un error", "error");
        });
      this.overlay = true;
    },
    enableEditMode: function() {
      if (this.isEditMode === true) {
        if (this.infoResponse.empresa.nruc) this.putClienteJuridico();
        else this.putClienteNatura();
      }
      this.isEditMode = true;
      this.editBtnColor = "blue";
      this.editBtnLabel = "Guardar Cambios";
    },
    enablePasswordEditMode: function() {
      if (this.isPasswordEditMode === true) {
        this.putPassword();
      }
      this.isPasswordEditMode = true;
      this.password = "";
      this.currentPassword = "";
      this.confirmPassword = "";
      this.editPasswordBtnColor = "blue";
      this.editPasswordBtnLabel = "Guardar Cambios";
    },
    sleep: function(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    putPassword: async function() {
      if (this.password != this.confirmPassword) {
        this.toasterMessageResponse("Las contraseñas no coinciden", "error");
        return 0;
      }
      let model = {
        passActual: this.currentPassword,
        password: this.password,
        confirmarPassword: this.confirmPassword,
      };
      var token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .put(this.$keys("CAMBIAR_CONTRASENA"), model)
        .then((response) => {
          if (response.status === 201) {
            this.toasterMessageResponse(response.data.mensaje, "success");
            this.overlay = true;
            this.isPasswordEditMode = false;
            this.editPasswordBtnLabel = "Editar";
            localStorage.removeItem(atob("usr"));
            this.sleep(2000).then(() => {
              window.location.href = "/IniciarSesion";
            });
          }
        })
        .catch((error) => {
          let errorResponse = JSON.parse(error.response.data.mensaje);
          this.toasterMessageResponse(errorResponse.mensaje, "error");
        });
      this.overlay = true;
    },
    getAccountInfo: async function() {
      let token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .get(this.$keys("INFO_CUENTA"))
        .then((response) => {
          if (response.status === 200) {
            this.infoJuridico = response.data;
            this.infoResponse = response.data;
            this.DiferencialCLiente = parseInt(response.data.idTipoCliente);
            console.log(this.DiferencialCLiente);
          }
        })
        .catch();
      this.overlay = true;
    },
     getClienteActividadJuridico: async function() {
      await this.$http
        .get(this.$keys("CLIENTE_ACTIVIDAD_JURIDICO"))
        .then((resp) => {
          if (resp.status == 200) {
            this.clienteActivadadJuridico = resp.data.items;
          }
        });
    },
    getTipoIdentificacion: async function() {
      await this.$http.get(this.$keys("TIPO_IDENTIFICACION")).then((resp) => {
        if (resp.status == 200) {
          this.identificacion = resp.data.items;
        }
      });
    },
    getTarjetas: async function() {
      await this.$http
        .get(
          this.$keys("TARJETA") +
            "?pagina=" +
            this.paginaTarjeta +
            "&registroPorPagina=" +
            this.registrosPorPaginasTarjetas
        )
        .then((resp) => {
          this.desserts = [];
          this.tarjetas = [];
          if (resp.status == 200) {
            if (resp.data.tieneItems === true) {
              this.tarjetas = resp.data.items;

              for (var i = 0; i < this.tarjetas.length; i++) {
                this.desserts.push({
                  numeration: this.getTarjetasDecode(
                    this.tarjetas[i].numeracion
                  ),
                  expiration: this.getTarjetasExpDecode(
                    this.tarjetas[i].mesExpira,
                    this.tarjetas[i].anioExpira
                  ),
                  titular: this.getTarjetasNombreDecode(
                    this.tarjetas[i].nombre
                  ),
                });
              }
              this.paginaTarjetaTotales = resp.data.paginado.totaldePaginas;
            }
          }
        });
    },
    getTarjetasDecode: function(item) {
      let dataT = atob(item);
      return dataT;
    },
    getTarjetasExpDecode: function(mes, anio) {
      let mesDecode = atob(mes);
      let anioDecode = atob(anio);
      return mesDecode + "/" + anioDecode;
    },
    getTarjetasNombreDecode: function(item) {
      let dataT = atob(item);
      return dataT;
    },
    getPais: async function() {
      await this.$http.get(this.$keys("PAIS")).then((resp) => {
        if (resp.status == 200) {
          this.paisList = resp.data;
        }
      });
    },
    getClienteActividad: async function() {
      await this.$http.get(this.$keys("CLIENTE_ACTIVIDAD")).then((resp) => {
        if (resp.status == 200) {
          this.clienteActividadList = resp.data;
        }
      });
    },
     getClasificacionJ: async function() {
      await this.$http
        .get(this.$keys("CLIENTE_JURIDICO_CLASIFICACION"))
        .then((resp) => {
          if (resp.status == 200) {
            console.log("clienteJuridicoClasificacion");
            console.log(resp.data.items);

            for (let index = 0; index < resp.data.items.length; index++) {
              const element = resp.data.items[index];
              this.clienteJuridicoClasificacion.push({
                idClasificacionJuridico: element.idClasificacionJuridico,
                nombre: element.nombre
              });  
            }

            console.log(this.clienteJuridicoClasificacion);
             
          }
        });
    },
  },
};
</script>
<style>
.card:not(.on-hover) {
  opacity: 0.2;
}
.theme--light.v-pagination .v-pagination__item--active {
  color: #ffffff;
  background-color: #085995 !important;
}
.card {
  transition: opacity 3s ease-in-out;
}
.fontD {
  color: #616161 !important;
  font-weight: 600;
  font-size: 36px !important;
}
@media (max-width: 725px) {
  .fontD {
    font-size: 22px !important;
  }
}
.text-start {
  text-align: start !important;
  color: #616161;
}
.img_fondo_visa {
  background-image: url(../../../assets/img/visa-cut.jpg);
  height: 157px;
  width: 270px;
}
.img_fondo-Mastercard {
  background-image: url(../../../assets/img/master-cut.jpg);
  height: 157px;
  width: 270px;
}

.N-Tarjeta {
  font-family: Myriad Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-with: 725px) {
  #card-visa {
    align-content: center !important;
  }
}
.appendRight {
  float: right;
}
.theme--light.v-select .v-select__selection--comma {
  color: #000 !important;
}
</style>
